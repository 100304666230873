import { GetStaticProps } from "next";
import React, { useEffect } from "react";
import { HELP_DESK_LINK } from "../assets/js/utils/constants";
import SEOTags from "../components/seo-tags";
import { toAppUrl } from "@/assets/js/utils/functions";

const LoginPage = () => {
  useEffect(() => {
    window.location.href = toAppUrl("login", true, true);
  }, []);

  return (
    <>
      <SEOTags
        tags={{
          title: "Login To Catlog",
          description: "Login to your Catlog dashboard",
          image: "https://res.cloudinary.com/catlog/image/upload/v1671181798/seo-banners/Main_Banner.png",
          pageUrl: "/login",
        }}
      />
      <div className="h-screen w-screen grid place-items-center">
        <span className="text-placeholder text-1sm">Redirecting....</span>
      </div>
    </>
  );
};

export default LoginPage;

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
  };
};
